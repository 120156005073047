import React, {useEffect, useState} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './PdfViewer.css'; // Подключаем CSS файл для стилизации

// Убедись, что путь к worker правильный


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



const PdfViewer = ({ pdfUrl }: { pdfUrl: string }) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [scale, setScale] = useState<number>(1.5); // Изначальный масштаб
	const handleResize = () => {
		const width = window.innerWidth;
		if (width < 768) {
			setScale(0.6); // Масштаб для мобильных устройств
		} else if (width < 1024) {
			setScale(1.2); // Масштаб для планшетов
		} else {
			setScale(1.5); // Масштаб для десктопов
		}
	};

	useEffect(() => {
		handleResize(); // Применяем масштаб при загрузке компонента
		window.addEventListener('resize', handleResize); // Изменяем масштаб при изменении размера окна
		return () => window.removeEventListener('resize', handleResize); // Очищаем событие при демонтировании компонента
	}, []);


	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};

	return (<div className="pdf-container">
			<a
				href={pdfUrl}
				download="Ефремов Дмитрий Senior Unity Developer.pdf"
				className="download-button"
			> Скачать PDF </a>


			<Document
				file={pdfUrl}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />))}
			</Document>
		</div>);
};

export default PdfViewer;